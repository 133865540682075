const jtwray = require("./jtwray-headshot.webp");
const artificialartist_preview = require("./artificialartist-preview.webp");
const kanyes8ball_preview = require("./kanyes8ball-preview.webp");
const rventure_preview = require("./rventure-preview.webp");
const twittercardapi_preview = require("./twittercardapi-preview.webp");
const tuckerwraymetwittercard_preview = require("./tuckerwray-metwittercard-preview.webp");
const wonderwomen_preview = require("./wonderwomen.webp");
export {
  wonderwomen_preview,
  kanyes8ball_preview,
  rventure_preview,
  artificialartist_preview,
  jtwray,
  tuckerwraymetwittercard_preview,
  twittercardapi_preview
};
